
import { defineComponent } from "vue";
import CodeHighlighter from "@/components/highlighters/CodeHighlighter.vue";
import { code6 } from "./data";

export default defineComponent({
  name: "checkable-tag",
  data() {
    return {
      checked: false
    };
  },
  methods: {
    onChange(checked) {
      this.checked = checked;
    }
  },
  components: {
    CodeHighlighter
  },
  setup() {
    return {
      code6
    };
  }
});
